<template>
  <v-content color="primary">
    <v-snackbar v-model="snackbar" :timeout="timeout" top>
      {{ t(text) }}
      <v-btn flat @click="snackbar = false">
        {{ t('Close') }}
      </v-btn>
    </v-snackbar>
    <v-container fluid id="main-container" grid-list-md>
      <v-dialog v-model="addSingleCard" max-width="290"
        v-if="this.$store.getters.isAdmin || this.$store.getters.isOwner">
        <v-form ref="formSingle">
          <v-card>
            <v-card-text class="text-center">
              <v-text-field v-model="cardId" :rules="[rules.counter, rules.number]" class="mt-0 pt-0" hide-details
                single-line type="number" style="width: 60px"></v-text-field>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-spacer></v-spacer>
              <v-btn outline small color="error" @click.stop="addSingleCard = false">{{ t('Close') }}</v-btn>
              <v-btn small text color="primary" @click.stop="createCard">{{ t('Add') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog v-model="addManyCards" max-width="290"
        v-if="this.$store.getters.isAdmin || this.$store.getters.isOwner">
        <v-form ref="form">
          <v-card>
            <v-card-text>
              <v-range-slider v-model="range" hide-details class="align-center" max="2147483647">
                <template v-slot:prepend>
                  <v-text-field v-model="range[0]" :rules="[rules.counter, rules.number]" class="mt-0 pt-0" hide-details
                    single-line type="number" style="width: 60px"></v-text-field>
                </template>
                <template v-slot:append>
                  <v-text-field v-model="range[1]" :rules="[rules.counter, rules.number]" class="mt-0 pt-0" hide-details
                    single-line type="number" style="width: 60px"></v-text-field>
                </template>
              </v-range-slider>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn outline small color="error" @click.stop="addManyCards = false">{{ t('Close') }}</v-btn>
              <v-btn small text color="primary" @click.stop="createManyCards">{{ t('Add') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-layout row wrap justify-center class="d-inline-block w-100 mb-5">
        <v-card>
          <v-card-title>
            <v-layout row wrap>
              <v-flex shrink class="pr-5 text-xs-center" align-center d-flex>
                <span class="menu-icon bigger material-symbols-outlined">
                  style
                </span>
                <span class="table-title">{{ t('Cards') }}</span>
              </v-flex>
              <v-flex grow class="pt-2 table-title" v-if="this.$store.getters.isAdmin || this.$store.getters.isOwner">
                <v-layout row>
                  {{ t('Active') }}: {{ activeCardsCount }}
                </v-layout>
                <v-layout row>
                  {{ t('Expired') }}: {{ expiredCardsCount }}
                </v-layout>
              </v-flex>
              <v-spacer v-else></v-spacer>
              <v-text-field v-model="search" append-icon="search" :label="t('Search')" single-line
                hide-details></v-text-field>
            </v-layout>
          </v-card-title>
          <v-data-table :headers="headers" :items="cards" :total-items="page_size" class="elevation-1"
            :loading="loading" :search="search" :pagination.sync="pagination" hide-actions
            :rows-per-page-items="[page_size]">
            <template slot="items" slot-scope="props">
              <td class="text-xs-left">{{ props.item.label }}</td>
              <td class="text-xs-left">{{ props.item.subscriber_name }}</td>
              <td class="text-xs-left">{{ $moment(props.item.created_at).format('YYYY-MM-DD HH:mm:ss') }}</td>
              <td class="text-xs-left">{{ props.item.last_change ? $moment(props.item.last_change).format('YYYY-MM-DD HH:mm:ss') : '-' }}</td>
              <td class="text-xs-left">{{ props.item.expired_date ? $moment(props.item.expired_date).format('YYYY-MM-DD HH:mm:ss') : '-' }}</td>
              <td class="text-xs-left">{{ t(props.item.status) }}</td>
              <!--<td class="text-xs-left">{{ props.item.price }}</td>-->
              <td class="text-xs-left">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="info" ripple icon small dark :to="`/cards/${props.item.pk}/details/`">
                      <v-icon small>info</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ t('Detail') }}</span>
                </v-tooltip>
              </td>
            </template>
            <template slot="no-data">
              <v-subheader>{{ t('No data available') }}</v-subheader>
            </template>
          </v-data-table>
          <div
            style="display: flex; flex-direction: row; justify-content: center; align-items: center; flex-wrap: wrap;">
            <v-pagination v-model="pagination.current" :length="pagination.total" :total-visible="6"></v-pagination>
            <v-select style="margin-left: 10px; max-width: 50px" :items="[10, 25, 50]" v-model="page_size">
            </v-select>
          </div>
        </v-card>
      </v-layout>
      <v-speed-dial v-model="fab" color="primary" dark fixed icon x-large bottom right direction="top"
        :open-on-hover="hover" transition="slide-y-transition"
        v-if="this.$store.getters.isAdmin || this.$store.getters.isOwner">
        <template v-slot:activator>
          <v-btn x-large icon v-model="fab" color="primary" dark>
            <v-icon v-if="fab">close</v-icon>
            <v-icon v-else>library_add</v-icon>
          </v-btn>
        </template>
        <v-btn dark small icon color="primary darken-1" @click.stop="addSingleCard = true">
          <v-icon>add</v-icon>
        </v-btn>
        <v-btn dark icon small color="primary darken-1" @click.stop="addManyCards = true">
          <v-icon>playlist_add</v-icon>
        </v-btn>
      </v-speed-dial>
    </v-container>
  </v-content>

</template>

<script>
import axios from 'axios';
import { debounce } from 'vue-debounce';

export default {
  name: "Cards",
  data() {
    return {
      activeCardsCount: undefined,
      expiredCardsCount: undefined,
      pagination: {
        current: 1,
        total: undefined,
        descending: undefined,
        sortBy: undefined,
      },
      page_size: 10,
      next: null,
      previous: null,
      count: 0,
      headers: [
        {
          text: this.$translate.locale['Card Label'],
          value: 'label',
        },
        {
          text: this.$translate.locale['Subscriber name'],
          value: 'first_name',
        },
        { text: this.$translate.locale['Issued'], value: 'created_at' },
        { text: this.$translate.locale['Edited'], value: 'last_change' },
        { text: this.$translate.locale['Expired'], value: 'expired_date' },
        { text: this.$translate.locale['Status'], value: 'status' },
        { text: this.$translate.locale['Action'], value: 'action', sortable: false },
      ],
      cards: [],
      cardId: null,
      loading: true,
      snackbar: false,
      search: '',
      fab: false,
      hover: true,
      text: 'Oops... Something went wrong',
      timeout: 5000,
      addManyCards: false,
      addSingleCard: false,
      min: 0,
      range: [],
      rules: {
        counter: value => (value <= 2147483647 && value >= 1) || this.$translate.locale['Min 1 and Max 2147483647'],
        number: value => {
          const pattern = /^\d+$/;
          return pattern.test(value) || this.$translate.locale['Invalid number.']
        },
      },
      getDataDebounced: debounce((...args) => this.getData(...args), '300ms'),
    }
  },
  methods: {
    getData(newSearch = false) {
      this.loading = true;

      // let url = this.pagination.current === 1
      //    ? `/api/cards/` + '?page_size=' + this.page_size
      //    : '/api/cards/?page=' + this.pagination.current + '&page_size=' + this.page_size;
      let url = `/api/cards/`
      let params = {};

      if (this.search) {
        params.search = this.search;

        if (this.pagination.current !== 1 && newSearch) {
          this.pagination.current = 1;
          // return, because changing `this.pagination.current` 
          // will trigger another call of getData()
          return
        }
      }

      params = {
        ...params,
        sortBy: this.pagination.sortBy,
        descending: this.pagination.descending,
        page: this.pagination.current,
        page_size: this.page_size,
      };

      axios.get(url, { params })
        .then((response) => {
          if (response.status === 200) {
            this.activeCardsCount = response.data.active_count;
            this.expiredCardsCount = response.data.expired_count;
            this.cards = response.data.results;
            this.next = response.data.next;
            this.previous = response.data.previous;
            this.count = response.data.count;
            this.pagination.total = response.data.total_pages;
            this.loading = false;
          }
        }).catch((error) => {
          if (error.response.status === 404) {
            this.text = 'Page not found';
            this.snackbar = true;
          }
        });
      // this.getRange();
    },
    createCard() {
      if (!this.$refs.formSingle.validate()) {
        this.text = "Fill the form correctly";
        this.snackbar = true;
      } else {
        axios.post(`/api/cards/create/range/`, { start: parseInt(this.cardId), stop: parseInt(this.cardId) })
          .then((response) => {
            if (response.status === 201) {
              this.getData();
              this.text = "Card has been created!";
              this.snackbar = true;
              this.addSingleCard = false;
            }
          }).catch((error) => {
            this.text = "Connection error";
            this.addSingleCard = false;
            this.snackbar = true;
          });
      }
    },
    createManyCards() {
      if (!this.$refs.form.validate()) {
        this.text = "Fill the form correctly";
        this.snackbar = true;
      } else {
        this.loading = true;
        axios.post(`/api/cards/create/range/`, { start: this.range[0], stop: this.range[1] })
          .then((response) => {
            if (response.status === 201) {
              this.getData();
              this.text = `Cards have been created!`;
              this.snackbar = true;
              this.addManyCards = false;
            }
          }).catch((error) => {
            this.addManyCards = false;
            this.text = "Connection error";
            this.snackbar = true;
          });
      }
    },
    // getRange() {
    //     let flag = this.$store.getters.isAdmin || this.$store.getters.isOwner;
    //     this.loading = true;
    //     if (flag) {
    //         axios.get(`/api/cards/create/range/`)
    //             .then((response) => {
    //                 if (response.status === 200) {
    //                     this.min = response.data.start;
    //                     this.loading = false;
    //                     this.range = [this.min, this.min + 10];
    //                     this.cardId = this.min;
    //                     // this.rules.counter = value => (value <= (this.min + 99) && value >= this.min) || 'Min 1 and Max 2147483647';
    //                 }
    //             }).catch((error) => {
    //             this.text = "Connection error";
    //             this.snackbar = true;
    //         });
    //     }
    // },
  },
  beforeCreate() {
    if (!this.$store.getters.loggedIn) {
      this.$router.push('/')
    }
  },
  mounted() {
    this.getData();
  },
  watch: {
    'pagination.current': function () { this.getDataDebounced(); },
    'pagination.sortBy': function () { this.getDataDebounced(); },
    'pagination.descending': function () { this.getDataDebounced(); },
    'page_size': function (newValue, oldValue) { this.getDataDebounced(newValue !== oldValue); },
    'search': function (newValue, oldValue) { this.getDataDebounced(newValue !== oldValue); },
  }
}
</script>

<style scoped></style>
